<template>
  <div class="page">
    <div class="card">
      <p style="width: 100%;text-align: center">招聘岗位数量</p>
      <Legend name="招聘数量"></Legend>
      <Chart type="line" :options="{legend: {display:false}}" :data="recruitNum"/>
    </div>
    <div class="card">
      <p style="width: 100%;text-align: center">应聘人数</p>
      <Legend name="应聘人数"></Legend>
      <Chart type="line" :options="{legend: {display:false}}"  :data="applyPeopleNum"/>
    </div>
    <div class="card">
      <p style="width: 100%;text-align: center">招聘平台注册数量</p>
      <Legend name="平台注册数量"></Legend>
      <Chart type="line" :options="{legend: {display:false}}"  :data="publicUserNumber"/>
    </div>
  </div>

</template>

<script>
import Legend from "@/views/platform/dataDiagram/legend";

export default {
  name: "recruitData",
  components: {Legend},
  data() {
    return {
      tableName: null,
      applyDate: 2,
      applyDateOption: [
        {name: '年', code: 1},
        {name: '月', code: 2},
        {name: '日', code: 3},
      ],
      recruitNum: null,
      applyPeopleNum: null,
      publicUserNumber: null
    }
  },
  mounted() {
    this.getDate()

  },
  methods: {
    changeDate(name) {
      this.tableName = name
      this.getDate()
    },
    getDate() {
      this.$http.get('/Statistics/recruit', {
        params: {
          tableName: this.tableName,
          date: this.applyDate
        }
      }).then((res) => {
        console.log(res.data)
        let recruitNum = res.data.recruitNum
        let labels = []
        let data = []

        for (let i = 0; i < recruitNum.length; i++) {
          labels.push(recruitNum[i].months)
          data.push(recruitNum[i].count)
        }

        this.recruitNum = {
          labels: labels,
          datasets: [
            {
              label: '招聘数量',
              fill: false,
              borderColor: '#42A5F5',
              data: data
            }
          ],
          options: {
            legend: {
              boxWidth: 1,
              fillStyle: 'color'
            }
          }
        }
        let applyPeopleNum = res.data.applyPeopleNum
        labels = []
        data = []
        for (let i = 0; i < applyPeopleNum.length; i++) {
          labels.push(applyPeopleNum[i].months)
          data.push(applyPeopleNum[i].count)
        }
        this.applyPeopleNum = {
          labels: labels,
          datasets: [
            {
              label: '应聘人数',
              fill: false,
              borderColor: '#42A5F5',
              data: data
            }
          ]
        }
        let publicUserNumber = res.data.publicUserNumber
        labels = []
        data = []
        for (let i = 0; i < publicUserNumber.length; i++) {
          labels.push(publicUserNumber[i].months)
          data.push(publicUserNumber[i].count)
        }
        this.publicUserNumber = {
          labels: labels,
          datasets: [
            {
              label: '招聘平台人数',
              fill: false,
              borderColor: '#42A5F5',
              data: data
            }
          ]
        }
      })
    }
  }
}
</script>

<style scoped>
.card {
  width: calc(50% - 7px);
}

.page {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: none;
  border: none;
}
</style>